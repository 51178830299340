import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import {
  Card,
  Dt,
  Page,
  Typography
} from '../../components/v2/styled';
import { PracticeLogoAvatar } from '../../components/v2/PracticeLogoAvatar';

import { NewsItem } from '../../models/NewsItem';
import { NutriMail } from '../../models/NutriMail';
import { Practice } from '../../models/Practice';
import { Workshop } from '../../models/Workshop';
import { User } from '../../models/User';

import HelpersPractice from '../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const RestrictedDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);








  const {
    data: newsItemsNutrilink,
    isLoading: newsItemsNutrilinkLoading,
    isError: newsItemsNutrilinkError,
    isSuccess: newsItemsNutrilinkSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["news_items", "Nutrilink","coach"],
    queryFn: () =>
      fetch_all<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'publisher', 'title', 'content', 'teaser', 'get_item', 'practice_id'],
          per_page: 1,
          page: 0,
          order: "published_at DESC",
          filter: {
            advanced: {
            //  practice_id: (login?.practice_id || currentPractice?.id),
              only_published: 1
            }
          }
        },
        login
      ),
    enabled: !!login,
  });
  
  const {
    data: nutriMails,
    isLoading: nutriMailsLoading,
    isError: nutriMailsError,
    isSuccess: nutriMailsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["nutri_mails", "restricted", login?.id],
    queryFn: () =>
      fetch_all<NutriMail>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'nutri_mail',
          fields: ['id', 'subject', 'read', 'created_at', 'nutri_mail_recipients'],
          sub_fields: {
            nutri_mail_recipients: ['id', 'recipient', 'read', 'deleted', 'replied'],
          },
          per_page: 5,
          page: 0,
          order: 'created_at DESC',
          filter: {
            advanced: {
              to_id: login?.id,
              deleted: 0,
              sent: 1
            }
          }
        },
        login
      ),
    enabled: !!login,
  });
  











  return <Page title={`${t("restricted.dashboard.hello", "Hallo")} ${login?.first_name},`}>
    <Grid container spacing={2}>

      <Card
        // title={login?.practice_name}
      >
        <PracticeLogoAvatar
          showName
          practice={{get_medium: login?.practice_get_medium, name: login?.practice_name, item_is_square: login?.practice_item_is_square} as Practice}
        />
      </Card>

      {!!nutriMailsSuccess && nutriMails.length > 0 && <Card
        title={t("coach.dashboard.nutri_mails.title", "Nieuwste NutriMails")}
        loading={nutriMailsLoading}
        actionNav="/inbox"
        actionName={t("coach.dashboard.nutri_mails.action")}
        wide
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          {!!nutriMailsSuccess && nutriMails.map(m => <>
            <Grid item xs={8} style={{fontWeight: (((m.nutri_mail_recipients || []).filter(fff => fff.recipient?.id === login?.id)[0] || {}).read ? 'normal' : 'bold')}}>
              {m.subject}
            </Grid>
            <Grid item xs={4} style={{fontWeight: (((m.nutri_mail_recipients || []).filter(fff => fff.recipient?.id === login?.id)[0] || {}).read ? 'normal' : 'bold')}}>
              {DateTime.fromISO(m.created_at).toFormat("DDD t")}
            </Grid>
          </>)}
        </Grid>
      </Card>}

      {!login?.extra_data?.restricted_saved && <Card
        title={t("restricted.dashboard.profile.title")}
        actionNav="/settings"
        actionName={t("restricted.dashboard.profile.action")}
      >
        <Typography>{t("restricted.dashboard.profile.body")}</Typography>
      </Card>}

      {!!newsItemsNutrilink && !!newsItemsNutrilink[0] && <Card
        loading={newsItemsNutrilinkLoading}
        actionNavFunc={() => {
          if (!!newsItemsNutrilink && !!newsItemsNutrilink[0]) navigate(`/news/${newsItemsNutrilink[0].id}`);
        }}
        actionName={t("admin.dashboard.news.action")}
        media={
          !!newsItemsNutrilink && !!newsItemsNutrilink[0] && newsItemsNutrilink[0].get_item ? [newsItemsNutrilink[0].get_item, newsItemsNutrilink[0].title] : undefined
        }
      >
        {!!newsItemsNutrilink && !!newsItemsNutrilink[0] && <>
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(newsItemsNutrilink[0].practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{newsItemsNutrilink[0].publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{newsItemsNutrilink[0].title}</Typography>
          <Typography html>{newsItemsNutrilink[0].teaser || (newsItemsNutrilink[0].content ? newsItemsNutrilink[0].content.substring(0, 100) : "")}</Typography>
        </>}
      </Card>}

    </Grid>
  </Page>;
}

import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  AppBar,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { EmailTemplate } from '../../../models/EmailTemplate';

// import HelpersPractice from '../../../actions/helpers/practice';
import HelpersEmailTemplate from '../../../actions/helpers/email_template';
import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {}

export const AdminEmailTemplateEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<EmailTemplate>({
    subject: ''
  });
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["admin", "email_template", objectId],
    queryFn: () =>
      fetch_one<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'email_template',
          id: objectId,
          fields: [
            'id', 'name', 'type', 'can_be_copied', 'full_subject', 'full_body', 'full_notification_body', 'full_push_body'
          ]
        },
        login
      ),
    enabled: !!login && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["admin", "email_templates"] });
      if (!variables.keepOpen) navigate("/email_templates");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/email_template/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    let trans:{[s:string]: {[n:number]: string}} = {
      'subject': {},
      'body': {},
      'notification_body': {},
      'push_body': {}
    };
    Object.keys(mainObject.full_subject || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
      trans['subject'][parseInt(mm, 10)] = (mainObject.full_subject || {})[parseInt(mm, 10)];
    })
    Object.keys(mainObject.full_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
      trans['body'][parseInt(mm, 10)] = (mainObject.full_body || {})[parseInt(mm, 10)];
    })
    Object.keys(mainObject.full_notification_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
      trans['notification_body'][parseInt(mm, 10)] = (mainObject.full_notification_body || {})[parseInt(mm, 10)];
    })
    Object.keys(mainObject.full_push_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
      trans['push_body'][parseInt(mm, 10)] = (mainObject.full_push_body || {})[parseInt(mm, 10)];
    })

    mutationSave.mutate({
      formData: {
        object: 'email_template',
        id: mainObject.id,
        fields: ['id'],
        ob: {
          id: mainObject.id,
          type: mainObject.type,
          name: mainObject.name,
          subject: (mainObject.full_subject || {})[1] || '',
          body: (mainObject.full_body || {})[1] || '',
          notification_body: (mainObject.full_notification_body || {})[1] || '',
          push_body: (mainObject.full_push_body || {})[1] || ''
        },
        translations: trans
      }
    });
  };

  let term = t('coach.models.email_templates.table_title');
  let termSingle = t("coach.models.email_templates.singular", "Nieuwsbericht");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = (mainObject || {}).subject || termSingle.toLowerCase();
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).type;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  
  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: term,
        path: "/email_templates"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("coach.models.email_templates.type", "Type")}
            displayEmpty
            backend
            id="type"
            value={mainObject.type || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                type: v.toString()
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.email_templates.placeholders.type", "Selecteer het type template")} ---</MenuItem>
            {HelpersEmailTemplate.mails_for_admin().map(tt => <MenuItem value={tt.value}>{tt.label}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            id="name"
            starred
            caption={t("coach.models.email_templates.name", "Naam")}
            placeholder={t("coach.models.email_templates.placeholders.name", "Vul hier de naam in")}
            value={mainObject.name || ''}
            backend
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                name: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
          <AppBar position="static" sx={{backgroundColor: 'white'}}>
            <Tabs value={currentLanguageId} onChange={(ev, i) => {
              setCurrentLanguageId(i);
              setFlickerEditor(true);
              setTimeout(() => {setFlickerEditor(false);}, 200);
            }}>
              {HelpersLanguage.available_locales().map((locale, i) => {
                return (<Tab value={HelpersLanguage.mapping_to_id(locale)} label={locale.toUpperCase()} id={`locale_tab_${locale}`} />)
              })}
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
          <TextInput
            id="subject"
            starred
            caption={t("coach.models.email_templates.subject", "Onderwerp")}
            placeholder={t("coach.models.email_templates.placeholders.subject", "Vul hier het onderwerp in")}
            value={(mainObject?.full_subject || {})[currentLanguageId] || ""}
            // backend
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                full_subject: {
                  ...(mainObject.full_subject || {}),
                  [currentLanguageId]: e
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
          {!flickerEditor && <Editor
            advanced
            label={t("coach.models.email_templates.body", "Body")}
            value={(mainObject?.full_body || {})[currentLanguageId] || ""}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                full_body: {
                  ...(mainObject.full_body || {}),
                  [currentLanguageId]: e
                }
              });
            }}
          />}
        </Grid>
        <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
          {!flickerEditor && <Editor
            advanced
            label={t("coach.models.email_templates.notification_body", "Notificatie inhoud")}
            value={(mainObject?.full_notification_body || {})[currentLanguageId] || ""}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                full_notification_body: {
                  ...(mainObject.full_notification_body || {}),
                  [currentLanguageId]: e
                }
              });
            }}
          />}
        </Grid>
        <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
          {!flickerEditor && <Editor
            advanced
            label={t("coach.models.email_templates.push_body", "Push inhoud")}
            value={(mainObject?.full_push_body || {})[currentLanguageId] || ""}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                full_push_body: {
                  ...(mainObject.full_push_body || {}),
                  [currentLanguageId]: e
                }
              });
            }}
          />}
        </Grid>


        







        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/email_templates");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Grid,
  ImageListItem,
  Paper,
} from '@mui/material';

import {
  Button,
  ImageList,
  Page,
  Typography
} from '../../../components/v2/styled';
import { CardCoach } from '../../../components/v2/cards/Coach';
import { CardLocation } from '../../../components/v2/cards/Location';
import { CardReview } from '../../../components/v2/cards/Review';
import { PracticeLogoAvatar } from '../../../components/v2/PracticeLogoAvatar';

import { Location } from '../../../models/Location';
import { Practice } from '../../../models/Practice';
import { QuestionnaireResult } from '../../../models/QuestionnaireResult';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientPracticeMyPractice: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const {
    data: practice,
    isLoading: practiceLoading,
    isError: practiceError,
    isSuccess: practiceSuccess,
    refetch: practiceRefetch,
  } = useQuery({
    queryKey: ["practice_full", login?.practice_id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: [
            'id', 'name', 'get_medium', 'item_is_square', 'get_my_practice', 'get_intro', 'settings', 'url_path'
          ],
          id: login?.practice_id
        },
        login
      ),
    // select: (d) => {
    //   return d[0];
    // },
    enabled: !!login?.practice_id,
  });

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", login?.practice_id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: [
            'id', 'first_name', 'last_name', 'get_medium', 'get_bg_medium', 'coach_skills', 'profile_text', 'settings', 'get_profile_text'
          ],
          order: "sort_order ASC, first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: login?.practice_id,
              is_secretary: 0,
              enabled: 1
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   return d[0];
    // },
    enabled: !!login?.practice_id,
  });

  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", login?.practice_id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name', 'get_medium', 'get_bg_medium', 'practice_types', 'is_online', 'street', 'number', 'zip', 'city', 'settings'
          ],
          filter: {
            search: '',
            advanced: {
              practice_id: login?.practice_id,
              enabled: 1,
              is_hidden: 0
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   return d[0];
    // },
    enabled: !!login?.practice_id,
  });

  const {
    data: questionnaireResults,
    isLoading: questionnaireResultsLoading,
    isError: questionnaireResultsError,
    isSuccess: questionnaireResultsSuccess,
    refetch: questionnaireResultsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "questionnaire_results", practice?.id],
    queryFn: () =>
      fetch_all<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'questionnaire_result',
          fields: [
            'id', 'extra_data', 'privacy_user', 'updated_at', 'completed_at'
          ],
          per_page: 5,
          page: 0,
          order: "updated_at DESC",
          filter: {
            search: '',
            advanced: {
              practice_id: practice?.id,
              is_frontpage: 1,
              is_review: 1,
              is_completed: 1
            }
          },
        }
      ),
    enabled: !!practice && !!practice.settings?.show_reviews_on_frontend,
  });







  const mutationLeave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/find");
    },
  });







  
  // const [currentWeight, setCurrentWeight] = useState<number>(lastWeight?.weight || 0);
  
  // useEffect(() => {
  //   if (!!practice?.weight) setCurrentWeight(practice?.weight);
  // }, [practice]);
  // useEffect(() => {
  //   if (!!lastWeight?.weight) setCurrentWeight(lastWeight?.weight || currentWeight || 0);
  // }, [lastWeight]);

  // let inputWeightDisabled = false;
  // if (!!practiceSuccess && !!practice.weight) inputWeightDisabled = true;

  return <Page>
    {!!practice && <Grid container spacing={2}>

      <Grid item sm={4} xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <PracticeLogoAvatar practice={practice} showName />
        </Paper>

        <Paper sx={{
          padding: 2,
          marginTop: 2
        }}>
          {!!login && <Button
            label={t("client.my_practice.leave_cta", "Verlaat deze praktijk")}
            id='leave_btn'
            contained
            fullwidth
            secondary
            onClick={(v) => {
              if (window.confirm(t("client.my_practice.leave_notice", "Bent u zeker dat u dit item wil verwijderen?"))) mutationLeave.mutate({
                formData: {
                  object: 'user',
                  class_action: "custom_api_leave_practice",
                  id: login?.id
                }
              });
            }}
            sx={{
              marginTop: 0,
              marginBottom: 3
            }}
          />}
          {!!practice?.settings?.is_allow_guest_accounts && <Button
            label={t("shared.buttons.make_appointment")}
            id='make_appointment'
            contained
            fullwidth
            onClick={(v) => {
              window.open(`https://${practice?.url_path}.nutriportal.eu/appointment`, "_BLANK");
            }}
            sx={{
              marginTop: 0,
              marginBottom: 3
            }}
          />}
          <Button
            label={t("shared.buttons.visit_portal")}
            id='visit_portal'
            contained
            fullwidth
            onClick={(v) => {
              window.open(`https://${practice?.url_path}.nutriportal.eu`, "_BLANK");
            }}
            sx={{
              marginTop: 0,
            }}
          />
        </Paper>
      </Grid>

      {(practice.get_intro || '').trim().length > 0 && <Grid item sm={8} xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Typography html>{practice.get_intro}</Typography>
        </Paper>
      </Grid>}
      {(practice.get_intro || '').trim().length < 1 && (practice.get_my_practice || '').trim().length > 0 && <Grid item sm={8} xs={12}>
        <Paper sx={{
          padding: 2
        }}>
          <Typography html>{practice.get_my_practice}</Typography>
        </Paper>
      </Grid>}

      {!!coaches && <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.coaches")}</Typography>
        <ImageList cardWidth={400}>
          {(coaches || []).map(coach => <ImageListItem key={coach.id} sx={{
            height: "auto !important",
          }}>
            <CardCoach
              coach={coach}
            />
          </ImageListItem>)}
        </ImageList>
      </Grid>}

      {!!locations && <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.locations")}</Typography>
        <ImageList cardWidth={400}>
          {(locations || []).map(location => <ImageListItem key={location.id} sx={{
            height: "auto !important",
          }}>
            <CardLocation
              location={location}
            />
          </ImageListItem>)}
        </ImageList>
      </Grid>}

      {!!practice?.settings?.show_reviews_on_frontend && (questionnaireResults || []).length > 0 && <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("general.login_dashboard.reviews", "Reviews")}</Typography>
        <ImageList cardWidth={400}>
          {(questionnaireResults || []).map(questionnaireResult => <ImageListItem key={questionnaireResult.id} sx={{
            height: "auto !important"
          }}>
            <CardReview
              questionnaireResult={questionnaireResult}
              sx={{
                width: '400px'
              }}
            />
          </ImageListItem>)}
        </ImageList>
      </Grid>}

      {/* <Grid item xs={12}>
        <Box sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          flex: 1
        }}>
          <Button
            id="leave_btn"
            contained
            secondary
            onClick={(e) => {
              if (window.confirm(t("client.my_practice.leave_notice", "Bent u zeker dat u dit item wil verwijderen?"))) mutationLeave.mutate({
                formData: {
                  object: 'user',
                  class_action: "custom_api_leave_practice",
                  id: login?.id
                }
              });
            }}
            label={t("client.my_practice.leave_cta", "Verlaat deze praktijk")}
          />
        </Box>
      </Grid> */}
    </Grid>}
  </Page>;
}

